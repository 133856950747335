import { createRoot } from "react-dom/client"
import { App } from "./components/App.tsx"
import * as Sentry from "@sentry/react"
import 'core-js/actual/map/group-by'

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: "https://1ea9c6f4bb86c70be9f6a76f65d519ea@o4508022103998464.ingest.de.sentry.io/4508022107013200",
		integrations: [Sentry.captureConsoleIntegration()],
	})
}

const container = document.getElementById("app")!
const root = createRoot(container)
root.render(<App />)

import { Instant, PlainDate } from "@imago/model"
import { useSetUserProfile, useSignOut } from "../api.ts"
import { useEffect } from "react"
import { Temporal } from "@imago/api-client"

export interface PrefillProfileContentJSON {
	timestamp: Temporal.Instant_ISO
	shortName?: string
	pronouns?: string
	legalName?: string
	dateOfBirth?: Temporal.PlainDate_ISO
}

export function Register() {
	const setUserProfile = useSetUserProfile()
	const signOut = useSignOut()

	let prefill
	try {
		const prefill_raw = localStorage.getItem('prefill-profile')
		prefill = prefill_raw ? JSON.parse(prefill_raw) as PrefillProfileContentJSON : undefined
		if (prefill && Instant.compare(prefill.timestamp, Temporal.Now.instant().subtract({hours: 24})) < 0) {
			prefill = undefined
			localStorage.removeItem('prefill-profile')
		}
	} catch (e) {
		console.warn(e)
	}

	useEffect(() => {
		document.documentElement.classList.add('light-only')
	}, [])

	return <div className="register">
		<main>
			<div>
				<form onSubmit={ev => {
					ev.preventDefault()
					ev.stopPropagation()
					const formData = new FormData(ev.currentTarget)
					setUserProfile.mutate({
						shortName: formData.get('shortName') as string,
						pronouns: formData.get('pronouns') as string,
						legalName: formData.get('legalName') as string,
						dateOfBirth: PlainDate.from(formData.get('dateOfBirth') as Temporal.PlainDate_ISO),
					})
				}} style={{alignItems: 'center', gap: '32px'}}>
					<img alt="Imago" src={new URL('../logo.svg', import.meta.url).toString()} style={{width: '50%'}}/>
					<h1>Welcome to Imago!</h1>
					<div style={{width: '80%'}}>
						<label>
							<span>Your chosen name</span>
							<input name="shortName" type="string" defaultValue={prefill?.shortName} autoFocus required/>
						</label>
						<label>
							<span>Your chosen pronouns</span>
							<input name="pronouns" type="string" defaultValue={prefill?.pronouns} required/>
						</label>
						<label>
							<span>Legal name</span>
							<input name="legalName" type="string" defaultValue={prefill?.legalName} required/>
						</label>
						<label>
							<span>Date of birth</span>
							<input name="dateOfBirth" type="date" defaultValue={prefill?.dateOfBirth} required/>
						</label>
					</div>
					<div style={{minWidth: '50%'}}>
					<button type="submit" disabled={setUserProfile.isPending}>Let’s go</button>
					<button onClick={ev => {
						ev.preventDefault()
						ev.stopPropagation()
						signOut.mutate()
					}} disabled={signOut.isPending}>Sign out</button>
					</div>
				</form>
			</div>
		</main>
	</div>
}

import { useGAHTIntaken, useGAHTIntake, useFakeGAHTIntake } from "../../FormDialog.tsx"
export { useGAHTIntaken as useIntaken }

function _Intake() {
	const intake = useGAHTIntake()!
	const fakeIntake = useFakeGAHTIntake()

	return <form onSubmit={ev => {
		ev.stopPropagation()
		ev.preventDefault()
		intake.mutate()
	}}>
		<p>To help you, we need to ask you some questions. Please give yourself 15 minutes to complete this form.</p>
		<p>The better we know you, the more efficient treatment we’ll be able to provide.</p>
		<div className="row">
			<button type="submit" disabled={intake.isPending}>Fill out the intake form</button>
			{fakeIntake ?
				<button onClick={ev => {
					ev.stopPropagation()
					ev.preventDefault()
					fakeIntake.mutate()
				}} disabled={fakeIntake.isPending}>TEST: Skip</button>
			: <></>}
		</div>
	</form>
}

export function Intake() {
	const done = useGAHTIntaken()
	return <section data-done={done ? true : undefined}>
		<h2>Tell us about yourself</h2>
		{!done ? <_Intake/> : <></>}
	</section>
}

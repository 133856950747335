import { useMutation, useMutationState } from "@tanstack/react-query"
import { useEffect, useState } from "react"

declare global {
	class BeforeInstallPromptEvent extends Event {
		prompt(): Promise<{
			outcome: 'accepted' | 'dismissed'
		}>
	}

	interface Window {
		addEventListener(type: 'beforeinstallprompt', listener: (this: Window, ev: BeforeInstallPromptEvent) => any, options?: boolean | AddEventListenerOptions): void
	}

	interface Navigator {
		userAgentData: {
			mobile: boolean | undefined
		} | undefined
	}
}

declare global {
	interface Window {
		_x_installApp: (() => Promise<boolean>) | undefined
	}
}

window.addEventListener('beforeinstallprompt', (ev: BeforeInstallPromptEvent) => {
	window._x_installApp = async () => {
		window._x_installApp = undefined
		window.dispatchEvent(new CustomEvent('_x_installabilitychanged'))
		return (await ev.prompt()).outcome === 'accepted'
	}
	window.dispatchEvent(new CustomEvent('_x_installabilitychanged'))
})

export function useInstallApp() {
	const [installable, setInstallable] = useState<boolean>(false)
	useEffect(() => {
		const callback = () => {
			setInstallable(window._x_installApp !== undefined)
		}
		window.addEventListener('_x_installabilitychanged', callback)
		setInstallable(window._x_installApp !== undefined)
		return () => {
			window.removeEventListener('_x_installabilitychanged', callback)
		}
	})
	const mutation = useMutation({
		mutationKey: ['install-app'],
		mutationFn: async () => {
			const res = await window._x_installApp!()
			// Wait for Chromium to emit another prompt.
			await new Promise(resolve => setTimeout(resolve, 100))
			return res
		}
	})
	const activeMutations = useMutationState({
		filters: {mutationKey: ['install-app']}
	}).filter(mutation => mutation.status === 'pending')

	return activeMutations.length || installable ? {
		...mutation,
		isPending: activeMutations.length >= 1,
	} : undefined
}

export function installationIcon() {
	const navMobile = navigator.userAgentData?.mobile
	if (navMobile !== undefined) return navMobile ? 'install_mobile' : 'install_desktop'

	const touchSupport = 'ontouchstart' in window || navigator.maxTouchPoints > 0
	return touchSupport ? 'install_mobile' : 'install_desktop'
}

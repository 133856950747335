import { useQuery, useMutation } from "@tanstack/react-query"
import { Temporal, URIObject } from "@imago/api-client"
import { AppointmentInfo, Intent, ServiceInfo } from "@imago/model"
import { useAppointmentsCacheManager, useServiceAppointment } from "../api.ts"
import { YCBM_Intents_cancel, YCBM_Intents_confirm, YCBM_Intents_getAvailability, YCBM_Intents_getAvailabilityKey, YCBM_Intents_selectSlot, YCBM_Intents_selectTeamMember, YCBMRemote } from "@imago/api-client-ycbm"

const writeToYCBM = true
const ycbm = new YCBMRemote(URIObject.parse('https://api.youcanbook.me'))

export function useBookableSlots(intent: Intent | undefined) {
	const ycbmIntentID = intent?.ycbm_secret

	return useQuery({
		queryKey: ['bookable-slots', ycbmIntentID],
		queryFn: async (): Promise<Temporal.ZonedDateTime[]> => {
			if (!intent || !ycbmIntentID) {
				return []
			}
			const data = await YCBM_Intents_getAvailabilityKey(ycbm, ycbmIntentID)
			const data2 = await YCBM_Intents_getAvailability(ycbm, data.key)
			return data2.slots.map(slot => Temporal.Instant.fromEpochMilliseconds(parseInt(slot.startsAt)).toZonedDateTimeISO(Temporal.Now.timeZoneId()))
		}
	})
}

export function useBookMutation({service, intent}: {
	service: ServiceInfo
	intent?: Intent
}) {
	const ycbmIntentID = intent?.ycbm_secret
	const isIntentBooked = useServiceAppointment(service.ID)

	const cacheManager = useAppointmentsCacheManager()
	const mutation = useMutation({
		mutationFn: async ({slot}: {
			slot: Temporal.ZonedDateTime
		}) => {
			try {
				if (writeToYCBM) {
					await YCBM_Intents_selectSlot(ycbm, ycbmIntentID!, {
						startsAt: slot.toInstant().epochMilliseconds,
						timeZone: slot.timeZoneId,
					})
					await YCBM_Intents_selectTeamMember(ycbm, ycbmIntentID!, {
						teamMemberId: "AUTO",
					})
					await YCBM_Intents_confirm(ycbm, ycbmIntentID!)
				}

				const appointment: AppointmentInfo = {
					serviceID: service.ID,
					duration: service.duration,
					start: slot,
					end: slot.add(service.duration),
					ycbm_secret: intent?.ycbm_secret
					// TODO googleMeetLink
				}
				cacheManager.add(appointment)
				return appointment
			} finally {
				cacheManager.invalidate()
			}
		}
	})
	return ycbmIntentID && !isIntentBooked ? mutation : undefined
}

export function useCancelBookingMutation({appointment}: {
	appointment: AppointmentInfo | undefined
}) {
	const ycbmIntentID = appointment?.ycbm_secret
	const isIntentBooked = useServiceAppointment(appointment?.serviceID)

	const cacheManager = useAppointmentsCacheManager()
	const mutation = useMutation({
		mutationFn: async () => {
			try {
				if (writeToYCBM) {
					await YCBM_Intents_cancel(ycbm, ycbmIntentID!)
				}

				cacheManager.remove(appointment!)
			} finally {
				cacheManager.invalidate()
			}
		}
	})

	return ycbmIntentID && isIntentBooked ? mutation : undefined
}

import { Fragment } from "react/jsx-runtime"
import { Temporal } from "@imago/api-client"
import { PrescriptionInfo } from "../api.ts"

const locale = 'en-GB'

export function PrescriptionView({prescription, full}: {
	prescription: PrescriptionInfo
	full?: boolean
}) {
	return <article>
		<hgroup>
			<h4>{
				prescription.receivedOn ? "Current prescription" :
				prescription.issuedOn ? "Incoming prescription" :
				prescription.requestedOn ? "Requested prescription" :
				"Draft prescription"
			}</h4>
			{!prescription.receivedOn && prescription.issuedOn ? <div role="toolbar">
				<button className="secondary">Track</button>
			</div> : <></>}
		</hgroup>
		{
			prescription.runsOutOn ? <dl>
				<dt>Runs out on</dt>
				<dd>{prescription.runsOutOn.toLocaleString(locale, {dateStyle: 'long'})}</dd>
			</dl> :
			prescription.issuedOn ? <dl>
				<dt>Issued on</dt>
				<dd>{prescription.issuedOn.toLocaleString(locale, {dateStyle: 'long'})}</dd>
			</dl> :
			prescription.requestedOn ? <dl>
				<dt>Requested on</dt>
				<dd>{prescription.requestedOn.toLocaleString(locale, {dateStyle: 'long'})}</dd>
			</dl> :
			prescription.basedOn ? <dl>
				<dt>Based on</dt>
				<dd>one issued on {prescription.basedOn.toLocaleString(locale, {dateStyle: 'long'})}</dd>
			</dl> :
			<></>
		}
		<hr data-total/>
		<section>
		{full ? <h6>Doctor's advice <small>(not on the physical prescription)</small></h6> : <></>}
		<p>{prescription.advice}</p>
		</section>
		{prescription.requestedOn && !prescription.issuedOn && prescription.requestersComment ? <>
		<hr/>
		<section>
			<h6>Your comment</h6>
			<p>{prescription.requestersComment}</p>
		</section>
		</> : <></>}
		{prescription.receivedOn || full ? prescription.items.map((item, index) => <Fragment key={index}>
			<hr/>
			<section>
				<h6>{item.title}</h6>
				<p>{item.dosageRegimen}</p>
				{full ? <p><small>Supply {item.quantity}</small></p> : <></>}
			</section>
		</Fragment>) : <></>}
		{!full ? <>
			<hr data-total/>
			<div className="row expander">
				<button className="low-prio">
					{prescription.receivedOn ? "" : "See more / "}
					Prescription details
				</button>
			</div>
		</> : <>
			<hr data-total/>
			<h5>Your legal details</h5>
			<dl>
				<dt>Legal name</dt>
				<dd>Marek Kowalski</dd>
			</dl>
			<dl>
				<dt>Date of birth</dt>
				<dd>{Temporal.PlainDate.from('2000-05-02').toLocaleString(locale, {dateStyle: "long"})}</dd>
			</dl>
		</>}
	</article>
}

import { ServiceProductInfo } from "@imago/model"
import { useProfile, useServiceProducts } from "../../api.ts"
import { Hello } from "../Hello.tsx"
import { useState } from "react"

function ServiceProductView({service}: {
	service: ServiceProductInfo
}) {
	const [detailsShown, setDetailsShown] = useState<boolean>(false)

	const profile = useProfile()
	const contactLink = 'mailto:support@imago.tg?' + new URLSearchParams({
		subject: `Book ${service.title}`,
		body: `Hello,\nI’d like to book ${service.title}.\n${profile?.shortName}`
	}).toString().replaceAll('+', '%20')
	return <article key={service.ID}>
		<hgroup onClick={ev => {
			ev.preventDefault()
			ev.stopPropagation()
			setDetailsShown(!detailsShown)
		}}>
			<h4>{service.title}</h4>
			<div role="toolbar">
				{detailsShown ? <></> : <a role="button" href={contactLink} className="low-prio only-on-medium-plus" target="_blank">Contact us</a>}
				<button>
					<span className="material-symbols-outlined">{detailsShown ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span>
				</button>
			</div>
		</hgroup>
		<div dangerouslySetInnerHTML={{__html: service.description!}}/>
		{detailsShown ? <>
			<p>Price: {service.price} / session</p>
			<a role="button" href={contactLink} className="primary" target="_blank">Contact us</a>
			{service.bio ? <>
				<hr/>
				<aside dangerouslySetInnerHTML={{__html: service.bio!}}/>
			</> : <></>}
		</> : <></>}
	</article>
}

export function Services({fullScreen}: {fullScreen?: boolean}) {
	const Section = fullScreen ? 'main' : 'div'
	const H = fullScreen ? 'h1' : 'h2'
	const services = useServiceProducts()

	return <Section>
		{fullScreen ? <Hello/> : <></>}
		<section>
			<H>Book a service</H>
			{services ? [...services.values()].map(service => <ServiceProductView key={service.ID} service={service}/>) : <></>}
		</section>
	</Section>
}

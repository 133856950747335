import { AppointmentView } from "./Book.tsx"
import { GAHT } from "./GAHT/index.tsx"
import { Services } from "./Services/index.tsx"
import { Hello } from "./Hello.tsx"
import { useAppointments } from "../api.ts"
import { Temporal } from "@imago/api-client"

export function Home() {
	const appointments = useAppointments()
	const currentAppointments = appointments === undefined ? undefined : appointments.filter(a => Temporal.Instant.compare(a.end.toInstant().add({hours: 12}), Temporal.Now.instant()) >= 0)

	return <main>
			<Hello/>
			{currentAppointments && currentAppointments.length ? <>
				<section>
					<h2>Your appointments</h2>
					{currentAppointments.map(appointment => <AppointmentView appointment={appointment}/>)}
				</section>
				<hr data-total/>
			</> : <></>}
			<GAHT/>
			<hr data-total/>
			<Services/>
	</main>
}

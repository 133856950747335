import { Stripe } from "@stripe/stripe-js"
import { loadStripe } from '@stripe/stripe-js/pure'
import { Elements, PaymentElement, useElements } from "@stripe/react-stripe-js"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { Intent } from "@imago/model"
import { useConfig } from "../api.ts"

function useColorScheme() {
	const darkPreferred = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)')
	const [colorScheme, setColorScheme] = useState(darkPreferred.matches ? 'dark' : 'light')
	useEffect(() => {
		darkPreferred.addEventListener('change', ev => {
			setColorScheme(ev.matches ? "dark" : "light")
		});
	}, [])
	return colorScheme
}

function useThemeColors() {
	useColorScheme() // ensure we re-render on every theme switch

	const style = getComputedStyle(document.body)
	return {
		primary: style.getPropertyValue('--md-sys-color-primary'),
		onSurface: style.getPropertyValue('--md-sys-color-on-surface'),
		onSurfaceVariant: style.getPropertyValue('--md-sys-color-on-surface-variant'),
		outline: style.getPropertyValue('--md-sys-color-outline'),
		outlineVariant: style.getPropertyValue('--md-sys-color-outline-variant'),
		surfaceContainerLowest: style.getPropertyValue('--md-sys-color-surface-container-lowest'),
		surfaceContainerLow: style.getPropertyValue('--md-sys-color-surface-container-low'),
		surfaceContainer: style.getPropertyValue('--md-sys-color-surface-container'),
		surfaceContainerHigh: style.getPropertyValue('--md-sys-color-surface-container-high'),
		surfaceContainerHighest: style.getPropertyValue('--md-sys-color-surface-container-highest'),
	}
}

let stripePromise: Promise<Stripe | null> | undefined = undefined

export function StripeElements({intent, children}: {
	intent: Intent
	children?: any
}) {
	const colors = useThemeColors()

	const config = useConfig()
	if (!stripePromise) stripePromise = loadStripe(config.stripePublishableKey)

	return intent ? <Elements stripe={stripePromise} options={{
		clientSecret: intent.stripe_secret!.clientSecret,
		customerSessionClientSecret: intent.stripe_secret!.customerSessionClientSecret,
		appearance: {
			theme: "flat",
			variables: {
				colorPrimary: colors.primary,
				colorText: colors.onSurface,
				borderRadius: '4px',
				focusBoxShadow: 'none',
				accordionItemSpacing: '0',
			},
			rules: {
				'.AccordionItem': {
					backgroundColor: 'transparent',
					paddingLeft: '16px',
					paddingRight: '16px',
					border: '0px solid transparent',
				},
				'.Input': {
					backgroundColor: colors.surfaceContainerHigh,
					borderRadius: '0',
					border: '0',
					paddingBottom: '15px',
					borderBottom: `1px solid ${colors.onSurfaceVariant}`,
					transition: 'none',
				},
				'.Input:focus': {
					paddingBottom: '14px',
					borderBottom: `2px solid ${colors.primary}`,
					transition: 'none',
				},
				'.CheckboxInput': {
					backgroundColor: 'transparent',
					border: `2px solid ${colors.onSurfaceVariant}`,
					borderRadius: '2px',
				},
				'.CheckboxInput--checked': {
					border: `2px solid ${colors.primary}`,
				},
				'.Block': {
					backgroundColor: 'transparent',
					boxShadow: 'none',
				},
				'.BlockDivider': {
					backgroundColor: 'transparent',
				},
				'.RedirectText': {
					borderTop: '0.75rem solid transparent',
				},
				'.PickerItem--selected, .PickerItem--selected:focus, .PickerItem--selected:hover': {
					backgroundColor: 'transparent',
					border: `1px solid ${colors.outlineVariant}`,
					boxShadow: 'none',
				},
				'.PickerItem--highlight, .PickerItem--highlight:focus, .PickerItem--highlight:hover': {
					backgroundColor: 'transparent',
					border: `1px solid ${colors.primary}`,
					boxShadow: `0 0 4px ${colors.primary}`,
				},
				'.PickerItem, .PickerItem:hover, .PickerItem:focus': {
					backgroundColor: 'transparent',
					border: `1px solid ${colors.outline}`,
					boxShadow: 'none',
				},
			},
		}
	}}>
		{children}
	</Elements> : <></>
}

export function StripePaymentElement() {
	const div = useRef<HTMLDivElement>(null)

	useLayoutEffect(() => {
		const pse = div.current!.getElementsByClassName('__PrivateStripeElement')[0] as HTMLDivElement
		const iframe = div.current!.getElementsByTagName('iframe')[0]!
		if (!pse || !iframe) return

		function pseOverride() {
			pse.style.height = ''
		}
		pseOverride()
		const pseObserver = new MutationObserver(pseOverride)
		pseObserver.observe(pse, {attributes: true, attributeFilter: ['style']})

		function iframeOverride() {
			iframe.style.transition = 'height 0.2s'

			// At the beginning, iframe has 2px height. No idea why, but this breaks transitions.
			if (iframe.style.height === '2px') {
				iframe.style.height = '313px'
			}
		}
		iframeOverride()
		const iframeObserver = new MutationObserver(iframeOverride)
		iframeObserver.observe(iframe, {attributes: true, attributeFilter: ['style']})

		return () => {
			pseObserver.disconnect()
			iframeObserver.disconnect()
		}
	}, [useElements()]) // the iframe will be there <=> elements !== null

	return <div ref={div}>
		<PaymentElement options={{
			layout: {
				type: 'accordion',
			}
		}}/>
	</div>
}

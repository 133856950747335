import { URIObject, Temporal, KyInstance } from '@imago/api-client'

const TF_FormID_Brand: unique symbol = Symbol()
export type TF_FormID = string & {[TF_FormID_Brand]: never}

const TF_FormFieldID_Brand: unique symbol = Symbol()
export type TF_FormFieldID = string & {[TF_FormFieldID_Brand]: never}

const TF_ResponseID_Brand: unique symbol = Symbol()
export type TF_ResponseID = string & {[TF_ResponseID_Brand]: never}

export interface TF_Form {
	created_at: Temporal.Instant_ISO
	fields: TF_FormField[]
	id: TF_FormID
	last_updated_at: Temporal.Instant_ISO
	published_at: Temporal.Instant_ISO
	settings: {}
	thankyou_screens: {}[]
	theme: {href: string}
	title: string
	type: string
	workspace: {href: string}
	_links: {}
}

export interface TF_FormField {
	id: TF_FormFieldID
	properties: {
		description: string
		fields?: TF_FormField[]
	}
	ref: string
	title: string
	type: string
	validations: {}
}

export interface TF_ResponseList {
	total_items: number
	page_count: number
	items: TF_Response[]
}

export interface TF_Response {
	token: TF_ResponseID
	//response_id: TF_ResponseID // available in GET, not available in webhook
	//landing_id: TF_ResponseID // available in GET, not available in webhook

	landed_at: Temporal.Instant_ISO
	submitted_at: Temporal.Instant_ISO

	response_type?: 'completed' // available in GET, not available in webhook

	metadata?: {}
	hidden?: {}
	answers: TF_Answer[]
	calculated?: {}
}

interface TF_AnswerBase {
	field: {
		id: TF_FormFieldID
		type: string
		ref: string
	}
}

interface TF_AnswerText extends TF_AnswerBase {
	type: "text"
	text: string
}

interface TF_AnswerEmail extends TF_AnswerBase {
	type: "email"
	email: string
}

interface TF_AnswerPhoneNumber extends TF_AnswerBase {
	type: "phone_number"
	phone_number: string
}

interface TF_AnswerNumber extends TF_AnswerBase {
	type: "number"
	number: number
}

interface TF_AnswerDate extends TF_AnswerBase {
	type: "date"
	date: string
}

interface TF_AnswerBoolean extends TF_AnswerBase {
	type: "boolean"
	boolean: boolean
}

interface TF_AnswerChoice extends TF_AnswerBase {
	type: "choice"
	choice: {
		id: string
		label: string
		ref: string
		other: undefined
	} | {
		id: string
		label: undefined
		ref: undefined
		other: string
	}
}

interface TF_AnswerChoices extends TF_AnswerBase {
	type: "choices"
	choices: {
		ids: string[]
		labels?: string[]
		refs?: string[]
		other?: string
	}
}

type TF_Answer = TF_AnswerText | TF_AnswerEmail | TF_AnswerPhoneNumber | TF_AnswerNumber | TF_AnswerDate | TF_AnswerBoolean | TF_AnswerChoice | TF_AnswerChoices

interface TF_Response_WebHook extends TF_Response {
	form_id: TF_FormID
}

export interface TF_WebHookEvent {
	event_id: string
	event_type: 'form_response'
	form_response: TF_Response_WebHook
}

export function TF_stringifyAnswer(answer: TF_Answer) {
	const answerText =
		answer.type === 'text' ? answer.text :
		answer.type === 'email' ? answer.email :
		answer.type === 'phone_number' ? answer.phone_number :
		answer.type === 'number' ? answer.number.toString() :
		answer.type === 'date' ? answer.date :
		answer.type === 'boolean' ? (answer.boolean ? 'Yes' : 'No') :
		answer.type === 'choice' ? (answer.choice.label ?? answer.choice.other) :
		answer.type === 'choices' ? [...answer.choices.labels ?? [], answer.choices.other].filter(label => label).join(', ') :
		(answer satisfies never, undefined)

	if (answerText === undefined) {
		throw new Error(`Unsupported answer type: ${answer.type}`)
	}

	return answerText
}

export class TFRemote {
	constructor(private uri: URIObject) {}

	get api() { return this.uri.api }
}

export async function TF_Forms_get(tf: TFRemote, formID: TF_FormID) {
	return await tf.api.get(`forms/${formID}`).json() as TF_Form
}

export async function TF_Responses_list(tf: TFRemote, formID: TF_FormID, {since, until, included_response_ids}: {
	since?: string
	until?: string
	included_response_ids?: Iterable<TF_ResponseID>
} = {}) {
	const resp = await tf.api.get(`forms/${formID}/responses`, {
		searchParams: {
			page_size: '1000',
			...(since ? {since} : {}),
			...(until ? {until} : {}),
			...(included_response_ids ? {included_response_ids: [...included_response_ids].join(',')} : {})
		}
	}).json() as TF_ResponseList
	if (resp.page_count !== 1) throw new Error('Paging unimplemented!') // TODO
	return resp.items
}

export async function TF_Responses_get(tf: TFRemote, formID: TF_FormID, responseID: TF_ResponseID) {
	const responses = await TF_Responses_list(tf, formID, {
		included_response_ids: [responseID]
	})
	const response = responses[0]
	if (!response) {
		throw new Error('No such response.')
	}
	return response
}

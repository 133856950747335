import FastURI from 'fast-uri'
import ky from 'ky'

export class URIObject {
	public scheme: string
	public userinfo: string | undefined
	public host: string | undefined
	public port: number | undefined
	public path: string
	public query: string | undefined
	public fragment: string | undefined

	constructor({scheme, userinfo, host, port, path, query, fragment}: {
		scheme: string
		userinfo: string | undefined
		host: string | undefined
		port: number | undefined
		path: string
		query: string | undefined
		fragment: string | undefined
	}) {
		this.scheme = scheme
		this.userinfo = userinfo
		this.host = host
		this.port = port
		this.path = path
		this.query = query
		this.fragment = fragment
	}

	static parse(uri: string): URIObject {
		const uriobject = FastURI.parse(uri)
		if (!uriobject.scheme || typeof uriobject.port === 'string') {
			throw new Error('not an URI')
		}
		return new URIObject({
			scheme: uriobject.scheme,
			userinfo: uriobject.userinfo,
			host: uriobject.host,
			port: uriobject.port,
			path: uriobject.path || '',
			query: uriobject.query,
			fragment: uriobject.fragment,
		})
	}

	get username(): string | undefined {
		if (!this.userinfo) return
		const colonPos = this.userinfo.indexOf(':')
		return decodeURIComponent(this.userinfo.substring(0, colonPos !== -1 ? colonPos : undefined))
	}

	get password(): string | undefined {
		if (!this.userinfo) return
		const colonPos = this.userinfo.indexOf(':')
		return colonPos !== -1 ? decodeURIComponent(this.userinfo.substring(colonPos + 1)) : undefined
	}

	get bearerToken(): string | undefined {
		return this.queryParams.get('access_token') ?? undefined
	}

	get queryParams(): URLSearchParams {
		return new URLSearchParams(this.query)
	}

	get fragmentParams(): URLSearchParams {
		return new URLSearchParams(this.fragment)
	}

	get api() {
		if (!(
			this.scheme === 'https' || this.scheme.endsWith('+https')
		)) {
			throw new Error('Invalid URI for URIObject.api() call.')
		}

		let http_auth
		if (this.username && this.password) {
			http_auth = `Basic ${btoa(`${this.username}:${this.password}`)}`
		} else if (this.bearerToken) {
			http_auth = `Bearer ${this.bearerToken}`
		}

		return ky.extend({
			timeout: 60000,
			prefixUrl: `https://${this.host}${this.path}`,
			headers: {
				authorization: http_auth,
			},
		})
	}
}

import { useState } from "react"
import { Hello } from "./Hello.tsx"
import { Temporal } from "@imago/api-client"
import { useProduct, useProfile, useSignOut, useSubscription } from "../api.ts"
import { Subscription_ID, SubscriptionInfo } from "@imago/model"
import { useCancelSubscription, useChangePaymentMethod, useSubscribe } from "./PaymentDialog.tsx"

const locale = 'en-GB'

export function AccountSettings() {
	const subscription = useSubscription()
	return subscription ? <_AccountSettings subscription={subscription}/> : <></>
}

export function _AccountSettings({subscription}: {
	subscription: SubscriptionInfo
}) {
	const user = useProfile()!

	const [yourDetailsShown, setYourDetailsShown] = useState<boolean>(false)
	const [legalDetailsShown, setLegalDetailsShown] = useState<boolean>(false)
	const [subscriptionDetailsShown, setSubscriptionDetailsShown] = useState<boolean>(!subscription.periodEnds || !subscription.renews)

	const subscriptionProduct = useProduct(Subscription_ID)
	const subscribe = useSubscribe()
	const changePaymentMethod = useChangePaymentMethod()
	const cancelSubscription = useCancelSubscription()
	const signOut = useSignOut()

	return <main>
		<Hello/>
		<section>
		<h1>Account settings</h1>
		<fieldset>
			<hgroup aria-controls="your-details" onClick={ev => {
				ev.preventDefault()
				ev.stopPropagation()
				setYourDetailsShown(!yourDetailsShown)
			}}>
				<h4>Your details</h4>
				<div role="toolbar">
					<button>
						<span className="material-symbols-outlined">{!yourDetailsShown ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</span>
					</button>
				</div>
			</hgroup>
			<div id="your-details" hidden={!yourDetailsShown}>
				<label>
					<span>Your chosen first name</span>
					<input type="text" value={user.shortName} readOnly/>
				</label>
				<label>
					<span>Your pronouns</span>
					<input type="text" value={user.pronouns} readOnly/>
				</label>
			</div>
		</fieldset>
		<fieldset>
			<hgroup aria-controls="legal-details" onClick={ev => {
				ev.preventDefault()
				ev.stopPropagation()
				setLegalDetailsShown(!legalDetailsShown)
			}}>
				<h4>Legal details</h4>
				<div role="toolbar">
					<button>
						<span className="material-symbols-outlined">{!legalDetailsShown ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</span>
					</button>
				</div>
			</hgroup>
			<div id="legal-details" hidden={!legalDetailsShown}>
				<label>
					<span>Your legal name</span>
					<input type="text" value={user.legalName} readOnly/>
				</label>
				<label>
					<span>Date of birth</span>
					<input type="date" value={user.dateOfBirth.toString()} readOnly/>
				</label>
			</div>
		</fieldset>
		<article>
			<hgroup aria-controls="subscription-details" onClick={ev => {
				ev.preventDefault()
				ev.stopPropagation()
				setSubscriptionDetailsShown(!subscriptionDetailsShown)
			}}>
				<h4>Imago subscription</h4>
				<dd hidden={subscriptionDetailsShown}>
					<strong style={{
						color: subscription.periodEnds && subscription.renews ? 'var(--md-sys-color-primary)' : 'var(--md-sys-color-error)'
					}}>{
						subscription.periodEnds && subscription.renews ? 'active' :
						subscription.periodEnds ? `ends at ${subscription.periodEnds.toZonedDateTimeISO(Temporal.Now.timeZoneId()).toLocaleString(locale, {day: 'numeric', month: 'short'})}` :
						'inactive'
					}</strong>
					{subscription.paymentPastDue ? <>
						,<br/><strong style={{color: 'var(--md-sys-color-error'}}>unpaid</strong>
					</> : <></>}
				</dd>
				<div role="toolbar">
					<button>
						<span className="material-symbols-outlined">{!subscriptionDetailsShown ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</span>
					</button>
				</div>
			</hgroup>
			<div id="subscription-details" hidden={!subscriptionDetailsShown}>
				<p>Imago subscription covers supervision and guidance over your transition process, GAHT prescription renewals and adjustments, support by our team, and access to our booking system.</p>
				<dl>
					<dt>Status</dt>
					<dd>
						<strong style={{
							color: subscription.periodEnds && subscription.renews ? 'var(--md-sys-color-primary)' : 'var(--md-sys-color-error'
						}}>{
							subscription.periodEnds && subscription.renews ? 'active' :
							subscription.periodEnds ? `ends at ${subscription.periodEnds.toZonedDateTimeISO(Temporal.Now.timeZoneId()).toLocaleString(locale, {dateStyle: 'full'})}` :
							'inactive'
						}</strong>
						{subscription.paymentPastDue ? <>
							, <strong style={{color: 'var(--md-sys-color-error'}}>unpaid</strong>
						</> : <></>}
					</dd>
				</dl>
				{subscription.periodEnds && subscription.renews ? <>
					<dl>
						<dt>{subscription.renews ? 'Next charge' : 'Ends at'}</dt>
						<dd>{subscription.periodEnds?.toZonedDateTimeISO(Temporal.Now.timeZoneId()).toLocaleString(locale, {dateStyle: 'full'})}</dd>
					</dl>
				</> : <></>}
				<dl>
					<dt>Price</dt>
					<dd>{subscriptionProduct?.price}</dd>
				</dl>
				{subscribe ? <>
					{subscription.trialAvailable ? <p><strong>The first month is on us.</strong></p> : <></>}
					<button onClick={ev => {
						ev.stopPropagation()
						ev.preventDefault()
						subscribe.mutate()
					}} disabled={subscribe.isPending} type="submit">{subscription.periodEnds ? 'Renew subscription' : 'Subscribe'}</button>
				</> : <></>}
				{cancelSubscription ? <>
					<button onClick={ev => {
						ev.stopPropagation()
						ev.preventDefault()
						cancelSubscription.mutate()
					}} disabled={cancelSubscription.isPending}>Cancel subscription</button>
				</> : <></>}
				{changePaymentMethod ? <>
					<button onClick={ev => {
						ev.stopPropagation()
						ev.preventDefault()
						changePaymentMethod.mutate()
					}} disabled={changePaymentMethod.isPending} type={subscription.paymentPastDue ? "submit" : undefined}>Change payment method</button>
				</> : <></>}
			</div>
		</article>
		<button onClick={ev => {
			ev.stopPropagation()
			ev.preventDefault()
			signOut.mutate()
		}} disabled={signOut.isPending}>Sign out</button>
		</section>
	</main>
}

import { docs_v1 } from "googleapis"
import { encodePathParameter, GAPI_SkipProps, GoogleAPI, GoogleWorkspaceRemote } from "./_base.ts"

const DocsV1API = new GoogleAPI('https://docs.googleapis.com/v1/')

type DocsV1_Documents_SkipProps = 'documentId' | GAPI_SkipProps

export async function DocsV1_Documents_create(
	remote: GoogleWorkspaceRemote,
	data: Omit<docs_v1.Schema$Document, 'documentId'>,
	options: Omit<docs_v1.Params$Resource$Documents$Create, DocsV1_Documents_SkipProps> = {}
) {
	return await DocsV1API.call<docs_v1.Schema$Document>(remote, 'POST', `documents`, options, data)
}

export async function DocsV1_Documents_get(
	remote: GoogleWorkspaceRemote,
	ID: string,
	options: Omit<docs_v1.Params$Resource$Documents$Get, DocsV1_Documents_SkipProps> = {}
) {
	return await DocsV1API.call<docs_v1.Schema$Document>(remote, 'GET', `documents/${encodePathParameter(ID)}`, options)
}

export async function DocsV1_Documents_batchUpdate(
	remote: GoogleWorkspaceRemote,
	ID: string,
	data: docs_v1.Schema$BatchUpdateDocumentRequest,
	options: Omit<docs_v1.Params$Resource$Documents$Batchupdate, DocsV1_Documents_SkipProps> = {}
) {
	return await DocsV1API.call<docs_v1.Schema$BatchUpdateDocumentResponse>(remote, 'POST', `documents/${encodePathParameter(ID)}:batchUpdate`, options, data)
}

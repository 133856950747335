import { serializer } from "@deepkit/type"
import { Temporal } from "@imago/api-client"
import { YCBM_IntentSecret } from "@imago/api-client-ycbm"
import { TF_FormID } from "@imago/api-client-typeform"
import { DocBlock } from "./api/SimpleDriveV1.ts"

export class Duration extends Temporal.Duration {}
export class Instant extends Temporal.Instant {}
export class ZonedDateTime extends Temporal.ZonedDateTime {}
export class PlainDate extends Temporal.PlainDate {}

serializer.deserializeRegistry.registerClass(ZonedDateTime, (type, state) => {
	state.setContext({ ZonedDateTime })
	state.addSetter(`ZonedDateTime.from(${state.accessor})`)
})
serializer.serializeRegistry.registerClass(ZonedDateTime, (type, state) => {
	state.addSetter(`${state.accessor}.toString()`)
})
serializer.deserializeRegistry.registerClass(Duration, (type, state) => {
	state.setContext({ Duration })
	state.addSetter(`Duration.from(${state.accessor})`)
})
serializer.serializeRegistry.registerClass(Duration, (type, state) => {
	state.addSetter(`${state.accessor}.toString()`)
})
serializer.deserializeRegistry.registerClass(Instant, (type, state) => {
	state.setContext({ Instant })
	state.addSetter(`Instant.from(${state.accessor})`)
})
serializer.serializeRegistry.registerClass(Instant, (type, state) => {
	state.addSetter(`${state.accessor}.toString()`)
})
serializer.deserializeRegistry.registerClass(PlainDate, (type, state) => {
	state.setContext({ PlainDate })
	state.addSetter(`PlainDate.from(${state.accessor})`)
})
serializer.serializeRegistry.registerClass(PlainDate, (type, state) => {
	state.addSetter(`${state.accessor}.toString()`)
})

const HTML__Brand: unique symbol = Symbol()
export type HTML = string & {[HTML__Brand]: never}

const ServiceID__Brand: unique symbol = Symbol()
export type ServiceID = string & {[ServiceID__Brand]: never}

const ProductID__Brand: unique symbol = Symbol()
export type ProductID = string & {[ProductID__Brand]: never}

export type ServiceProductID = ServiceID & ProductID

export const WelcomeCall_ID = 'imago.welcome-call' as ServiceID
export const GAHTOnboarding_ID = 'imago.gaht.onboarding' as ProductID
export const GAHTSetupCall_ID = 'imago.gaht.setup-call' as ServiceID
export const GAHTTransferCall_ID = 'imago.gaht.transfer-call' as ServiceID
export const Subscription_ID = 'imago.subscription' as ProductID

export interface ServiceInfo {
	ID: ServiceID
	title: string
	duration: Duration
	freezeTime: Duration
}

export interface ProductInfo {
	ID: ProductID
	title: string
	productType: 'single' | 'subscription'
	price: string
	description?: HTML
	bio?: HTML
}

export type ServiceProductInfo = ServiceInfo & ProductInfo
export type ServiceOrProductInfo = ServiceInfo | ProductInfo

export function isService(ps: ServiceOrProductInfo): ps is ServiceInfo {
	return (ps as ServiceInfo).duration !== undefined
}

export function isProduct(ps: ServiceOrProductInfo): ps is ProductInfo {
	return (ps as ProductInfo).productType !== undefined
}

export function isServiceProduct(ps: ServiceOrProductInfo): ps is ServiceProductInfo {
	return isService(ps) && isProduct(ps)
}

export interface AppointmentInfo {
	serviceID: ServiceID
	start: ZonedDateTime
	end: ZonedDateTime
	duration: Duration

	googleMeetLink?: string

	ycbm_secret?: YCBMIntentSecret
}

export interface SubscriptionInfo {
	productID: ProductID // always = Subscription_ID
	trialAvailable: boolean
	renews: boolean
	periodEnds: Instant | null
	paymentPastDue: boolean
}

const EMRID__Brand: unique symbol = Symbol()
export type EMRID = string & {[EMRID__Brand]: never}

const EMRKindID__Brand: unique symbol = Symbol()
export type EMRKindID = string & {[EMRKindID__Brand]: never}

export const Profile_ID = 'imago.user-profile' as EMRKindID
export const Welcomed_ID = 'imago.welcomed' as EMRKindID
export const Migrated_ID = 'imago.migrated' as EMRKindID
export const GAHTBloodResults_ID = 'imago.gaht.blood-results' as EMRKindID
export const GAHTPrescriptionDraft_ID = 'imago.gaht.prescription-draft' as EMRKindID
export const GAHTPrescriptionRequest_ID = 'imago.gaht.prescription-request' as EMRKindID
export const GAHTPrescriptionDocument_ID = 'imago.gaht.prescription-document' as EMRKindID
export const GAHTIntake_ID = 'imago.gaht.intake-form' as EMRKindID
export const GAHTFHTConsent_ID = 'imago.gaht.fht-consent' as EMRKindID
export const GAHTMHTConsent_ID = 'imago.gaht.mht-consent' as EMRKindID
export const EncounterScratchpad_ID = 'imago.encounter-scratchpad' as EMRKindID
export const EncounterNoShow_ID = 'imago.encounter-no-show' as EMRKindID

const MimeType__Brand: unique symbol = Symbol()
export type MimeType = string & {[MimeType__Brand]: never}

export const Folder_MimeType = 'application/vnd.google-apps.folder' as MimeType
export const Document_MimeType = 'application/vnd.google-apps.document' as MimeType

export interface EMRInfo {
	ID: EMRID
	timestamp: Instant
	kindID: EMRKindID
	mimeType: MimeType

	readable?: DocBlock[]

	prescriptionDraft?: PrescriptionDraftContent
	prescriptionRequest?: PrescriptionRequestContent
	prescriptionDocument?: PrescriptionDocumentContent
	userProfile?: UserProfileContent
}

export interface UserProfileContent {
	shortName: string
	pronouns: string

	legalName: string
	dateOfBirth: PlainDate
}

const TherapyID__Brand: unique symbol = Symbol()
export type TherapyID = string & {[TherapyID__Brand]: never}

export const GAHTFHT_ID = 'imago.gaht.fht' as TherapyID
export const GAHTMHT_ID = 'imago.gaht.mht' as TherapyID

export interface PrescriptionContent {
	notes?: string
	therapy: TherapyID
	advice: string
	items: {
		title: string
		quantity: string
		dosageRegimen: string
	}[]
}

export interface PrescriptionDraftContent {
	draft: PrescriptionContent
}

export interface PrescriptionRequestContent {
	draft: PrescriptionContent
	comment: string
	mailingAddress: string
}

export interface PrescriptionDocumentContent {
	prescription: PrescriptionContent
	mailingAddress: string
}

interface _IntentRequest {
	verb: string
}

interface BuyIntentRequest extends _IntentRequest {
	verb: 'buy'
	productID: ProductID
}

interface SubscribeIntentRequest extends _IntentRequest {
	verb: 'subscribe'
	productID: ProductID
	canGoWithoutTrial?: boolean
}

interface ChangePaymentMethodIntentRequest extends _IntentRequest {
	verb: 'change-payment-method'
}

interface ConfigurePayments extends _IntentRequest {
	verb: 'configure-payments'
}

interface BookIntentRequest extends _IntentRequest {
	verb: 'book'
	serviceID: ServiceID
}

interface FileIntentRequest extends _IntentRequest {
	verb: 'file'
	formID: EMRKindID
}

export type IntentRequest = BuyIntentRequest | SubscribeIntentRequest | ChangePaymentMethodIntentRequest | ConfigurePayments | BookIntentRequest | FileIntentRequest

export interface Intent {
	stripe_secret?: StripeIntentSecret
	ycbm_secret?: YCBMIntentSecret
	tf_secret?: TFIntentSecret
	tf_secret_v2?: TFIntentSecretV2
	url?: URL
}

const StripeIntentSecret__Brand: unique symbol = Symbol()
export type StripeIntentSecret = {
	[StripeIntentSecret__Brand]: never
	clientSecret: string
	customerSessionClientSecret: string
}

export type YCBMIntentSecret = YCBM_IntentSecret

const TFIntentSecret__Brand: unique symbol = Symbol()
export type TFIntentSecret = TF_FormID & {[TFIntentSecret__Brand]: never}

export type TFIntentSecretV2 = {
	[TFIntentSecret__Brand]: never
	formID: TF_FormID
	hidden: Record<string, string>
}

export interface AppConfig {
	testMode: boolean
	stripePublishableKey: string
}

export interface FatUser {
	appConfig: AppConfig

	appointments?: AppointmentInfo[]
	inventory?: ProductID[]
	subscription?: SubscriptionInfo
	emrs?: EMRInfo[]
}

export const TEMPORARILY_HERE_productsAndServices: ServiceOrProductInfo[] = [
	{
		ID: WelcomeCall_ID,
		title: 'Welcome call',
		duration: Duration.from({minutes: 15}),
		freezeTime: Duration.from({hours: 24}),
	},
	{
		ID: GAHTOnboarding_ID,
		title: 'GAHT onboarding',
		productType: "single",
		price: '€250',
	},
	{
		ID: GAHTSetupCall_ID,
		title: 'GAHT setup call',
		duration: Duration.from({minutes: 45}),
		freezeTime: Duration.from({hours: 24}),
	},
	{
		ID: GAHTTransferCall_ID,
		title: 'GAHT transfer call',
		duration: Duration.from({minutes: 15}),
		freezeTime: Duration.from({hours: 24}),
	},
	{
		ID: Subscription_ID,
		title: "Imago",
		productType: "subscription",
		price: '€20/month',
	},
	{
		ID: 'imago.services.mental-health-counselling' as ServiceID & ProductID,
		title: 'Mental health counselling',
		duration: Duration.from({minutes: 60}),
		freezeTime: Duration.from({hours: 24}),
		productType: "single",
		price: '€60',
		description: `<p>Tame the demons tormenting your psyche.</p>` as HTML,
		bio: `<p><b>Amanda Comet</b> (she/her) has a broad experience in psychiatry, giving counselling for suicidal ideation, trauma, substance abuse, and personality syndromes as well as everyday challenges related to executive dysfunction, anxiety, ADHD, and autism. Together with you, she will develop tools and strategies to navigate your issues as well as stabilize and improve your mental wellbeing. Amanda is fluent in Swedish and English and have life experience as a transperson with a visual disability. <a href="https://www.imago.tg/blog/introducing-imago-s-mental-health-support/">Read Amanda’s letter of introduction</a></p>` as HTML,
	},
	{
		ID: 'imago.services.vocal-coaching' as ServiceID & ProductID,
		title: 'Vocal coaching',
		duration: Duration.from({minutes: 45}),
		freezeTime: Duration.from({hours: 24}),
		productType: "single",
		price: '€50',
		description: `<p>Find your authentic voice and vocal autonomy with our vocal dysphoria coach!</p>` as HTML,
	},
	{
		ID: 'imago.services.fertility-counselling' as ServiceID & ProductID,
		title: 'Fertility counselling',
		duration: Duration.from({minutes: 40}),
		freezeTime: Duration.from({hours: 24}),
		productType: "single",
		price: '€50',
		description: `<p>Do you need advice regarding GAHT’s effect on fertility, perhaps you want to store your gametes?</p>` as HTML,
	},
]

const TestUserTemplateID__Brand: unique symbol = Symbol()
export type TestUserTemplateID = string & {[TestUserTemplateID__Brand]: never}

export interface TestUserTemplateInfo {
	ID: TestUserTemplateID
	shortName: string
	description: string
}

export const TEMPORARILY_HERE_testUserTemplates: TestUserTemplateInfo[] = [
	{
		ID: 'adam' as TestUserTemplateID,
		shortName: "Adam",
		description: "a new user",
	},
	{
		ID: 'eve' as TestUserTemplateID,
		shortName: "Eve",
		description: "an onboarding user",
	},
	{
		ID: 'kasia' as TestUserTemplateID,
		shortName: "Kasia",
		description: "a greenlighted user",
	},
	{
		ID: 'beata' as TestUserTemplateID,
		shortName: "Beata",
		description: "a long-time user",
	},
]

import { useContext } from "react"
import { Temporal } from "@imago/api-client"
import { PrescriptionView } from "../PrescriptionView.tsx"
import { Hello } from "../Hello.tsx"
import { RenewPrescription } from "./Renew.tsx"
import { PageSetContext } from "../App.tsx"
import { useIsIssuable } from "./tasks/TakeBloodTests.tsx"
import { RequestedPrescriptionInfo, useGAHTPrescriptions } from "../../api.ts"

export function YourGAHT({fullScreen}: {fullScreen?: boolean}) {
	const pres = useGAHTPrescriptions()
	const isIssuable = useIsIssuable()
	const prescriptions = pres?.filter(p => p.issuedOn || isIssuable(p as RequestedPrescriptionInfo))
	const currentPrescription = prescriptions?.filter(p => p.receivedOn)[0]
	const incomingPrescription = prescriptions?.filter(p => !p.receivedOn)[0]

	const Section = fullScreen ? 'main' : 'div'
	const H = fullScreen ? 'h1' : 'h2'

	const setPage = useContext(PageSetContext)

	return <>
	<Section>
		{fullScreen ? <Hello/> : <></>}
		<section>
			<H>Your GAHT</H>
			{!incomingPrescription && currentPrescription ? <>
				<article className="runout">
					<p><strong>
						Your prescription runs out in {Temporal.Now.plainDateISO().until(currentPrescription.runsOutOn!).days} days!
					</strong></p>
					<button className="primary" onClick={ev => {
						ev.stopPropagation()
						ev.preventDefault()
						setPage('gaht/renew-prescription')
					}}>Request another prescription</button>
				</article>
			</> : <></>}
			{prescriptions ? prescriptions.map(p => <PrescriptionView key={(p.issuedOn ?? p.requestedOn!).toString()} prescription={p}/>) : <></>}
		</section>
	</Section>
	{fullScreen ? <RenewPrescription/> : <></>}
	</>
}

import { calendar_v3 } from "googleapis"
import { encodePathParameter, GAPI_SkipProps, GoogleAPI, GoogleWorkspaceRemote } from "./_base.ts"

const CalendarV3API = new GoogleAPI('https://www.googleapis.com/calendar/v3/')

type CalendarV3_Events_SkipProps = 'calendarId' | 'eventId' | GAPI_SkipProps

export async function CalendarV3_Events_list(
	remote: GoogleWorkspaceRemote,
	calendarID: string,
	options: Omit<calendar_v3.Params$Resource$Events$List, 'eventTypes' | 'privateExtendedProperty' | 'sharedExtendedProperty' | CalendarV3_Events_SkipProps> = {} // TODO add those options
) {
	return await CalendarV3API.call<calendar_v3.Schema$Events>(remote, 'GET', `calendars/${encodePathParameter(calendarID)}/events`, options)
}

export async function CalendarV3_Events_get(
	remote: GoogleWorkspaceRemote,
	calendarID: string,
	ID: string,
	options: Omit<calendar_v3.Params$Resource$Events$Get, CalendarV3_Events_SkipProps> = {}
) {
	return await CalendarV3API.call<calendar_v3.Schema$Event>(remote, 'GET', `calendars/${encodePathParameter(calendarID)}/events/${encodePathParameter(ID)}`, options)
}

export async function CalendarV3_Events_patch(
	remote: GoogleWorkspaceRemote,
	calendarID: string,
	ID: string,
	data: Omit<calendar_v3.Schema$Event, 'id'>,
	options: Omit<calendar_v3.Params$Resource$Events$Patch, CalendarV3_Events_SkipProps> = {}) {
	return await CalendarV3API.call<calendar_v3.Schema$Event>(remote, 'PATCH', `calendars/${encodePathParameter(calendarID)}/events/${encodePathParameter(ID)}`, options, data)
}

type CalendarV3_CalendarList_SkipProps = GAPI_SkipProps

export async function CalendarV3_CalendarList_list(
	remote: GoogleWorkspaceRemote,
	options: Omit<calendar_v3.Params$Resource$Calendarlist$List, CalendarV3_CalendarList_SkipProps> = {}
) {
	return await CalendarV3API.call<calendar_v3.Schema$CalendarList>(remote, 'GET', `users/me/calendarList`, options)
}

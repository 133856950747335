import { Temporal } from "@imago/api-client"
import { useEffect, useState } from "react"

export function useNow() {
	const [now, setNow] = useState(Temporal.Now.zonedDateTimeISO())

	useEffect(() => {
		const timer = setInterval(() => {
			setNow(Temporal.Now.zonedDateTimeISO())
		})

		return () => clearInterval(timer)
	}, [])

	return now
}

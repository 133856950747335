import { drive_v3 } from "googleapis"
import { encodePathParameter, GAPI_SkipProps, GoogleAPI, GoogleWorkspaceRemote } from "./_base.ts"

const DriveV3A_API = new GoogleAPI('https://www.googleapis.com/drive/v3/', 'https://www.googleapis.com/upload/drive/v3/', {supportsAllDrives: true})

type DriveV3A_Files_SkipProps = 'fileId' | 'supportsAllDrives' | 'supportsTeamDrives' | GAPI_SkipProps

export async function DriveV3A_Files_list(
	remote: GoogleWorkspaceRemote,
	options: Omit<drive_v3.Params$Resource$Files$List, DriveV3A_Files_SkipProps> = {}
) {
	return await DriveV3A_API.call<drive_v3.Schema$FileList>(remote, 'GET', `files`, options)
}

export async function DriveV3A_Files_create(
	remote: GoogleWorkspaceRemote,
	data: Omit<drive_v3.Schema$File, 'id'> & {media?: Blob},
	options: Omit<drive_v3.Params$Resource$Files$Create, DriveV3A_Files_SkipProps> = {}
) {
	return await DriveV3A_API.call<drive_v3.Schema$File>(remote, 'POST', `files`, options, data)
}

export async function DriveV3A_Files_get(
	remote: GoogleWorkspaceRemote,
	ID: string,
	options: Omit<drive_v3.Params$Resource$Files$Get, DriveV3A_Files_SkipProps> = {}
) {
	return await DriveV3A_API.call<drive_v3.Schema$File>(remote, 'GET', `files/${encodePathParameter(ID)}`, options)
}

export async function DriveV3A_Files_getMedia(
	remote: GoogleWorkspaceRemote,
	ID: string,
	options: Omit<drive_v3.Params$Resource$Files$Get, DriveV3A_Files_SkipProps> = {}
) {
	return await DriveV3A_API.call(remote, 'GET', `files/${encodePathParameter(ID)}`, options, undefined, 'media')
}

export async function DriveV3A_Files_update(
	remote: GoogleWorkspaceRemote,
	ID: string,
	data: Omit<drive_v3.Schema$File, 'id'> & {media?: Blob},
	options: Omit<drive_v3.Params$Resource$Files$Update, DriveV3A_Files_SkipProps> = {}
) {
	return await DriveV3A_API.call<drive_v3.Schema$File>(remote, 'PATCH', `files/${encodePathParameter(ID)}`, options, data)
}

import { useContext } from "react"
import { Hello } from "../Hello.tsx"
import { PageSetContext } from "../App.tsx"
import { TakeBloodTests, useBloodTestsTaken } from "./tasks/TakeBloodTests.tsx"
import { Intake, useIntaken } from "./tasks/Intake.tsx"
import { Pay, usePaid } from "./tasks/Pay.tsx"
import { TalkWithAPhysician } from "./tasks/TalkWithAPhysician.tsx"

export function PrepareYourGAHT({fullScreen}: {fullScreen?: boolean}) {
	const paymentDone = usePaid()
	const intakeDone = useIntaken()
	const bloodTestsDone = useBloodTestsTaken()
	const bookingAllowed = paymentDone && intakeDone && bloodTestsDone || false

	const Section = fullScreen ? 'main' : 'div'
	const H = fullScreen ? 'h1' : 'h2'

	const setPage = useContext(PageSetContext)

	return <Section>
		{fullScreen ? <Hello/> : <></>}
		<section>
			<H>Prepare your GAHT</H>
			<p>To start your Gender-Affirming Hormone Therapy at&nbsp;Imago, you need to do four things:</p>
			<ul className="steps">
				<li data-done={paymentDone ? true : undefined}>pay,</li>
				<li data-done={intakeDone ? true : undefined}>tell us about yourself,</li>
				<li data-done={bloodTestsDone ? true : undefined}>take blood tests,</li>
				<li>talk with our physician.</li>
			</ul>
			{!fullScreen ? <>
				<button type="submit" onClick={ev => {
					ev.preventDefault()
					ev.stopPropagation()
					setPage('gaht')
				}}>Continue</button>
			</> : <></>}
		</section>
		{!fullScreen ? <></> : <>
			<hr data-total/>
			<Pay/>
			<hr data-total/>
			<Intake/>
			<hr data-total/>
			<TakeBloodTests/>
			<hr data-total/>
			<TalkWithAPhysician allowed={bookingAllowed}/>
			<hr data-total/>
			<section>
				<h2>Next steps</h2>
				<p>After talking with the physician, you’ll be able to make an informed decision whenever to start the therapy or not.</p>
				<p>If you decide to start, we’ll mail you a physicial prescription - that you can use in any pharmacy in the European Union.</p>
			</section>
		</>}
	</Section>
}

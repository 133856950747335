import { useElements, useStripe } from "@stripe/react-stripe-js"
import { CancelButton, createDialog, DialogProvider, HTMLDialogImplementation, useCancelDialog, useDialog, useDialogWith } from "./Dialog.tsx"
import { StripeElements, StripePaymentElement } from "./Stripe.tsx"
import { ProductID, Subscription_ID } from "@imago/model"
import { useIntent, useOwns, useProduct, useSubscription, useUnsubscribe_internal } from "../api.ts"
export { useOwns }

const PaymentDialog = createDialog<{productID: ProductID | null}>()
const UnsubscribeDialog = createDialog<{}>()

export function PaymentDialogProvider({children}: {children: any}) {
	return <DialogProvider dialog={PaymentDialog} Implementation={HTMLDialogImplementation(PaymentDialogContent)} >
		<DialogProvider dialog={UnsubscribeDialog} Implementation={HTMLDialogImplementation(UnsubscribeDialogContent)}>
			{children}
		</DialogProvider>
	</DialogProvider>
}

function usePaymentIntent(productID: ProductID | null) {
	if (productID === null) {
		return useIntent({verb: 'change-payment-method'})
	}

	const product = useProduct(productID)
	if (product === undefined) {
		throw new Error('Wait for products to load before issuing an intent.')
	}
	const subscription = useSubscription()
	if (subscription === undefined) {
		throw new Error('Wait for subscription to load before issuing an intent.')
	}

	if (product.productType === 'single')
		return useIntent({verb: 'buy', productID})
	else if (product.productType === 'subscription')
		return useIntent({verb: 'subscribe', productID, canGoWithoutTrial: !subscription.trialAvailable})
	else
		throw new Error('Impossible')
}

function PaymentDialogContent({productID}: {
	productID: ProductID | null
}) {
	const intent = usePaymentIntent(productID)
	return intent ? <StripeElements intent={intent}>
		<_PaymentDialogContent productID={productID}/>
	</StripeElements> : <></>
}

function _PaymentDialogContent({productID}: {
	productID: ProductID | null
}) {
	const product = productID ? useProduct(productID) : null
	const stripe = useStripe()
	const elements = useElements()

	return <form onSubmit={async ev => {
		ev.stopPropagation()
		ev.preventDefault()
		const { error } = await ((product && product.productType === 'single') ? stripe!.confirmPayment : stripe!.confirmSetup)({
			elements: elements!,
			confirmParams: {
				return_url: location.href,
			},
		})
		console.error(error)
	}}>
		<h4>
			{
				product === undefined ? '' :
				product === null ? `Change payment method` :
				product.productType === 'subscription' ? `Subscribe to ${product.title}` :
				product.productType === 'single' ? `Buy ${product.title}` :
				undefined
			}
		</h4>
		<div>
			<StripePaymentElement/>
		</div>
		<div className="row">
			<button type="submit" disabled={stripe === undefined || elements === undefined || product === undefined}>{product && product.productType === 'subscription' ? `Subscribe for ${product.price}` : product ? `Pay ${product.price}` : `Change`}</button>
			<CancelButton>Cancel</CancelButton>
		</div>
	</form>
}

function UnsubscribeDialogContent() {
	const cancelDialog = useCancelDialog()
	const unsubscribe = useUnsubscribe_internal()
	return <form onSubmit={async ev => {
		ev.stopPropagation()
		ev.preventDefault()
		unsubscribe.mutate(undefined, {
			onSuccess: () => cancelDialog()
		})
	}}>
		<h4>Cancel subscription?</h4>
		<div>
			<p>Are you sure you want to cancel the Imago subscription?</p>
			<p>Imago subscription covers supervision and guidance over your transition process, GAHT prescription renewals and adjustments, support by our team, and access to our booking system.</p>
		</div>
		<div className="row">
			<button type="submit" disabled={unsubscribe.isPending}>Cancel subscription</button>
			<CancelButton>Remain subscribed</CancelButton>
		</div>
	</form>
}

export function useBuy() {
	return useDialog(PaymentDialog)
}

export function useSubscribe() {
	const subscription = useSubscription()
	const dialog = useDialogWith(PaymentDialog, {productID: Subscription_ID})

	if (!subscription) return
	return !subscription.renews ? (dialog ? {
		...dialog,
		trialAvailable: subscription.trialAvailable,
	} : undefined) : null
}

export function useSubscribed() {
	const subscription = useSubscription()
	if (!subscription) return
	return subscription.periodEnds ? true : false
}

export function useChangePaymentMethod() {
	const subscription = useSubscription()
	const dialog = useDialogWith(PaymentDialog, {productID: null})

	if (!subscription) return
	return subscription.paymentPastDue || subscription.renews ? dialog : null
}

export function useCancelSubscription() {
	const subscription = useSubscription()
	const dialog = useDialogWith(UnsubscribeDialog, {})

	if (!subscription) return
	return subscription.renews ? dialog : null
}

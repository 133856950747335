import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { useMutation, UseMutationResult } from "@tanstack/react-query"

export type RunDialogRef<Params> = React.MutableRefObject<((param: Params) => Promise<void>)>
export type DialogImplementation<Params> = ({runDialogRef}: {runDialogRef: RunDialogRef<Params>}) => JSX.Element

class Dialog<Params> {
	context: React.Context<RunDialogRef<Params> | undefined>

	constructor() {
		this.context = createContext<RunDialogRef<Params> | undefined>(undefined)
	}
}

export function createDialog<Params>() {
	return new Dialog<Params>()
}

export function DialogProvider<Params>({dialog, Implementation, children}: {
	dialog: Dialog<Params>
	Implementation: DialogImplementation<Params>
	children: any
}) {
	const runDialogRef = useRef<((param: Params) => Promise<void>)>(async () => {throw new Error('`runDialogRef` not set.')})

	return <>
		<dialog.context.Provider value={runDialogRef}>
			{children}
		</dialog.context.Provider>

		<Implementation runDialogRef={runDialogRef}/>
	</>
}

export function useDialog<Params>(dialog: Dialog<Params>): UseMutationResult<void, Error, Params, unknown> {
	const runDialogRef = useContext(dialog.context)
	if (runDialogRef === undefined) {
		throw new Error('Dialog not provided.')
	}
	const mut = useMutation({
		mutationFn: async (param: any) => { // deepkit crashes with param: Params
			await runDialogRef.current(param)
		}
	})
	return mut
}

export function useDialogWith<Params>(dialog: Dialog<Params>, param: Params) {
	const runDialogRef = useContext(dialog.context)
	if (runDialogRef === undefined) {
		throw new Error('Dialog not provided.')
	}
	const mut = useMutation({
		mutationFn: async () => {
			await runDialogRef.current(param)
		}
	})
	return mut
}

const CurrentHTMLDialog = createContext<React.RefObject<HTMLDialogElement> | undefined>(undefined)

export function HTMLDialogImplementation<Params>(Content: (param: Params) => JSX.Element, {fullScreen}: {
	fullScreen?: boolean
} = {}): DialogImplementation<Params> {
	return ({runDialogRef}: {
		runDialogRef: any
	}) => {
		const [openDialog, setOpenDialog] = useState<{
			param: any
			resolve: () => void
		}>()

		runDialogRef.current = (param: any) => {
			return new Promise<void>((resolve, reject) => {
				setOpenDialog({
					param,
					resolve
				})
			})
		}

		const resolveDialog = () => {
			if (!openDialog) return
			const {resolve} = openDialog
			setOpenDialog(undefined)
			resolve()
		}

		const dialogRef = useRef<HTMLDialogElement>(null)
		useEffect(() => {
			const dialog = dialogRef.current!
			if (openDialog) dialog.showModal()
		}, [openDialog])

		return createPortal(<dialog
			ref={dialogRef}
			onClose={resolveDialog}
			className={fullScreen ? 'fullscreen' : 'basic'}
		>
			<CurrentHTMLDialog.Provider value={dialogRef}>
				{openDialog !== undefined ? <Content {...openDialog.param}/> : <></>}
			</CurrentHTMLDialog.Provider>
		</dialog>, document.body)
	}
}

function useCurrentHTMLDialog() {
	return useContext(CurrentHTMLDialog)
}

export function useCancelDialog() {
	const dialogRef = useCurrentHTMLDialog()
	if (!dialogRef) throw new Error('useCancel outside of a dialog')

	return () => {
		if (dialogRef.current!.dispatchEvent(new Event('cancel', {cancelable: true}))) {
			dialogRef.current!.close()
		}
	}
}

export function CancelButton({children}: {children: any}) {
	// formmethod="dialog" is not good enough, because it fires the close event, not the cancel event.
	const cancel = useCancelDialog()

	return <button
		onClick={ev => {
			ev.stopPropagation()
			ev.preventDefault()
			cancel()
		}}
	>
		{children}
	</button>
}

import { useContext } from "react"
import { Hello } from "../Hello.tsx"
import { PageSetContext } from "../App.tsx"
import { Consent, useConsented } from "./tasks/Consent.tsx"
import { Subscribe, useSubscribed } from "./tasks/Subscribe.tsx"

export function ReadyToGAHT({fullScreen}: {fullScreen?: boolean}) {
	const subscriptionDone = useSubscribed()
	const consentDone = useConsented()

	const Section = fullScreen ? 'main' : 'div'
	const H = fullScreen ? 'h1' : 'h2'

	const setPage = useContext(PageSetContext)

	return <Section>
		{fullScreen ? <Hello/> : <></>}
		<section>
			<H>Ready to GAHT?</H>
			<p>Thanks for speaking with our physician. We hope they were able to provide you all the information about the Gender-Affirming Hormone Therapy you needed.</p>
			<p>Now, it’s time for <strong>your decision</strong> - do you want to start the therapy?</p>
			<p>If so:</p>
			<ul className="steps">
				<li data-done={consentDone ? true : undefined}>give us your consent,</li>
				<li data-done={subscriptionDone ? true : undefined}>set up the Imago subscription,</li>
				<li>wait for the prescription to arrive!</li>
			</ul>
			{!fullScreen ? <>
				<button type="submit" onClick={ev => {
					ev.preventDefault()
					ev.stopPropagation()
					setPage('gaht')
				}}>Continue</button>
			</> : <></>}
		</section>
		{!fullScreen ? <></> : <>
			<hr data-total/>
			<Consent/>
			<hr data-total/>
			<Subscribe/>
			<hr data-total/>
			<section>
				<h2>Next steps</h2>
				<p>After you finish the above steps - we’ll mail you a physicial prescription - that you can use in any pharmacy in the European Union.</p>
				<p><i>Note: We’re still working on our app - and the next part isn’t finished yet. Our team will assist you through emails until we get it done.</i></p> {/* TODO */}
			</section>
		</>}
	</Section>
}

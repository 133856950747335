import { docs_v1, drive_v3 } from "googleapis"
import { DocsV1_Documents_batchUpdate, DocsV1_Documents_get, DriveV3A_Files_create, DriveV3A_Files_getMedia, DriveV3A_Files_list, DriveV3A_Files_update } from "@imago/api-client-google-workspace"
import type { GoogleWorkspaceRemote } from "@imago/api-client-google-workspace"

export type DocBlockClass = 'TITLE' | 'HEADING_1' | 'HEADING_2' | 'HEADING_3' | 'HEADING_4' | 'HEADING_5' | 'HEADING_6' | 'NORMAL_TEXT'

export interface DocBlock {
	class: DocBlockClass
	text: string
}

export interface SimpleDriveRemote {
	google: GoogleWorkspaceRemote
	tmpFolderID: string
}

export async function SimpleDriveV1_Files_list(remote: SimpleDriveRemote, folderID: string) {
	const files = await DriveV3A_Files_list(remote.google, {
		includeItemsFromAllDrives: true,
		pageSize: 1000,
		q: `trashed = false and '${folderID}' in parents`,
	})
	return files
}

export async function SimpleDriveV1_Files_createEmpty(remote: SimpleDriveRemote, folderID: string, name: string) {
	return await DriveV3A_Files_create(remote.google, {
		name,
		parents: [folderID],
		mimeType: 'application/octet-stream',
	})
}

export async function SimpleDriveV1_Files_createAndSetContent(remote: SimpleDriveRemote, folderID: string, name: string, content: Blob) {
	return await DriveV3A_Files_create(remote.google, {
		name,
		parents: [folderID],
		media: content,
	})
}

export async function SimpleDriveV1_Files_rename(remote: SimpleDriveRemote, ID: string, name: string) {
	return await DriveV3A_Files_update(remote.google, ID, {
		name
	})
}

export async function SimpleDriveV1_Files_delete(remote: SimpleDriveRemote, ID: string) {
	return await DriveV3A_Files_update(remote.google, ID, {
		trashed: true,
	})
}

export async function SimpleDriveV1_Folders_createAndSetContent(remote: SimpleDriveRemote, folderID: string, name: string, content: Map<string, Blob>) {
	const file = await DriveV3A_Files_create(remote.google, {
		name,
		parents: [remote.tmpFolderID],
		mimeType: "application/vnd.google-apps.folder",
	})
	await SimpleDriveV1_Folders_setContent(remote, file.id!, content)
	return await DriveV3A_Files_update(remote.google, file.id!, {}, {
		addParents: folderID,
		removeParents: remote.tmpFolderID,
	})
}

export async function SimpleDriveV1_Folders_createEmpty(remote: SimpleDriveRemote, folderID: string, name: string) {
	return await DriveV3A_Files_create(remote.google, {
		name,
		parents: [folderID],
		mimeType: 'application/vnd.google-apps.folder',
	})
}

export async function SimpleDriveV1_Folders_getContent(remote: SimpleDriveRemote, ID: string): Promise<Map<string, Blob>> {
	const folder = await SimpleDriveV1_Files_list(remote, ID)
	const files = await Promise.all(folder.files!.map(async file => [file.name!, await DriveV3A_Files_getMedia(remote.google, file.id!)] satisfies [string, Blob]))
	return new Map(files)
}

export async function SimpleDriveV1_Folders_setContent(remote: SimpleDriveRemote, ID: string, files: Map<string, Blob>): Promise<void> {
	await Promise.all([...files].map(async ([name, blob]) => await DriveV3A_Files_create(remote.google, {
		name,
		parents: [ID],
		media: blob,
	})))
}

export async function SimpleDriveV1_Documents_createAndSetContent(remote: SimpleDriveRemote, folderID: string, name: string, content: DocBlock[]) {
	const file = await DriveV3A_Files_create(remote.google, {
		name,
		parents: [remote.tmpFolderID],
		mimeType: 'application/vnd.google-apps.document',
	})
	await SimpleDriveV1_Documents_setContent(remote, file.id!, content)
	return await DriveV3A_Files_update(remote.google, file.id!, {}, {
		addParents: folderID,
		removeParents: remote.tmpFolderID,
	})
}

export async function SimpleDriveV1_Documents_createEmpty(remote: SimpleDriveRemote, folderID: string, name: string) {
	return await DriveV3A_Files_create(remote.google, {
		name,
		parents: [folderID],
		mimeType: 'application/vnd.google-apps.document',
	})
}

export async function SimpleDriveV1_Documents_getContent(remote: SimpleDriveRemote, ID: string): Promise<DocBlock[]> {
	const doc = await DocsV1_Documents_get(remote.google, ID)
	return doc.body?.content?.map(x => x.paragraph).filter(para => para !== undefined).map(para => {
		const textWithNewline = para.elements?.map(y => y.textRun?.content).filter(y => y).join('') || '\n'
		if (textWithNewline[textWithNewline.length - 1] != '\n') throw new Error('Newline expected')
		const text = textWithNewline.substring(0, textWithNewline.length - 1)
		return {
			class: para.paragraphStyle?.namedStyleType as DocBlockClass ?? 'NORMAL_TEXT',
			text: text.replaceAll('\v', '\n'),
		} satisfies DocBlock
	}) ?? []
}

export async function SimpleDriveV1_Documents_setContent(remote: SimpleDriveRemote, ID: string, blocks: DocBlock[]): Promise<drive_v3.Schema$File> {
	const doc = await DocsV1_Documents_get(remote.google, ID)

	const endIndex = (doc.body?.content?.pop()?.endIndex || 1) - 1

	const vBlocks = blocks.map(block => {return {class: block.class, text: block.text.replaceAll('\n', '\v')}})

	const requests = Array<docs_v1.Schema$Request>()

	if (endIndex > 1)
		requests.push({deleteContentRange: {range: {startIndex: 1, endIndex}}})

	if (vBlocks.length) {
		requests.push({insertText: {text: vBlocks.map(l => l.text).join('\n'), endOfSegmentLocation: {}}})
		let currentIndex = 1
		for (const line of vBlocks) {
			requests.push({updateParagraphStyle: {
				paragraphStyle: {namedStyleType: line.class},
				fields: 'namedStyleType',
				range: {startIndex: currentIndex, endIndex: currentIndex + 1},
			}})
			currentIndex += line.text.length + 1
		}
	}

	if (requests.length) {
		await DocsV1_Documents_batchUpdate(remote.google, ID, {
			requests
		})
	}

	return {
		id: doc.documentId,
		mimeType: 'application/vnd.google-apps.document',
		name: doc.title,
	}
}

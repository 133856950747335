import { sheets_v4 } from "googleapis"
import { encodePathParameter, GAPI_SkipProps, GoogleAPI, GoogleWorkspaceRemote } from "./_base.ts"

const SheetsV4API = new GoogleAPI('https://sheets.googleapis.com/v4/')

type SheetsV4_Spreadsheets_SkipProps = 'spreadsheetId' | GAPI_SkipProps

export async function SheetsV4_Spreadsheets_get(
	remote: GoogleWorkspaceRemote,
	ID: string,
	options: Omit<sheets_v4.Params$Resource$Spreadsheets$Get, SheetsV4_Spreadsheets_SkipProps> = {}
) {
	return await SheetsV4API.call<sheets_v4.Schema$Spreadsheet>(remote, 'GET', `spreadsheets/${encodePathParameter(ID)}`, options)
}

export async function SheetsV4_Spreadsheets_batchUpdate(
	remote: GoogleWorkspaceRemote,
	ID: string,
	data: sheets_v4.Schema$BatchUpdateSpreadsheetRequest,
	options: Omit<sheets_v4.Params$Resource$Spreadsheets$Batchupdate, SheetsV4_Spreadsheets_SkipProps> = {}
) {
	return await SheetsV4API.call<sheets_v4.Schema$BatchUpdateSpreadsheetResponse>(remote, 'POST', `spreadsheets/${encodePathParameter(ID)}:batchUpdate`, options, data)
}

export async function SheetsV4_Spreadsheets_updateValues(
	remote: GoogleWorkspaceRemote,
	ID: string,
	range: string,
	data: sheets_v4.Schema$ValueRange,
	options: Omit<sheets_v4.Params$Resource$Spreadsheets$Values$Update, SheetsV4_Spreadsheets_SkipProps | 'range'> = {}
) {
	return await SheetsV4API.call<sheets_v4.Schema$UpdateValuesResponse>(remote, 'PUT', `spreadsheets/${encodePathParameter(ID)}/values/${encodePathParameter(range)}`, options, data)
}

import { useBuy, useOwns } from "../../PaymentDialog.tsx"
import { GAHTOnboarding_ID } from "@imago/model"

export function usePaid() {
	return useOwns(GAHTOnboarding_ID)
}

function _Pay() {
	const buy = useBuy()!

	return <form onSubmit={async ev => {
		ev.stopPropagation()
		ev.preventDefault()
		buy.mutate({productID: GAHTOnboarding_ID})
	}}>
		<p>Starting Gender-Affirming Hormone Therapy at Imago costs <strong>€250</strong>.</p>
		<p>This includes the video-call with the physician and the support provided by our team.</p>
		<div className="row">
			<button type="submit" disabled={buy.isPending}>Pay €250</button>
		</div>
	</form>
}

export function Pay() {
	const done = usePaid()
	return <section data-done={done ? true : undefined}>
		<h2>Pay</h2>
		{!done ? <_Pay/> : <></>}
	</section>
}

import { URIObject, Temporal, KyResponse } from '@imago/api-client'

const YCBM_AccountID_Brand: unique symbol = Symbol()
export type YCBM_AccountID = string & {[YCBM_AccountID_Brand]: never}

const YCBM_Subdomain_Brand: unique symbol = Symbol()
export type YCBM_Subdomain = string & {[YCBM_Subdomain_Brand]: never}

const YCBM_ProfileID_Brand: unique symbol = Symbol()
export type YCBM_ProfileID = string & {[YCBM_ProfileID_Brand]: never}

const YCBM_RefRaw_Brand: unique symbol = Symbol()
export type YCBM_RefRaw = string & {[YCBM_RefRaw_Brand]: never}

const YCBM_RefFormatted_Brand: unique symbol = Symbol()
export type YCBM_RefFormatted = string & {[YCBM_RefFormatted_Brand]: never}

const YCBM_IntentSecret__Brand: unique symbol = Symbol()
export type YCBM_IntentSecret = string & {[YCBM_IntentSecret__Brand]: never}

declare const YCBM_AvailabilityKey__Brand: unique symbol;
export type YCBM_AvailabilityKey = string & {[YCBM_AvailabilityKey__Brand]: never}

export interface YCBM_ProfileInfo {
	id: YCBM_ProfileID
	locale: string
	logo: string
	subdomain: YCBM_Subdomain
	teamsActive: boolean
	title: string
	createdAt: Temporal.Instant_ISO
	updatedAt: Temporal.Instant_ISO
}

type YCBM_ProfilesResponse = YCBM_ProfileInfo[]

type YCBM_IntentInfo_Selections_Form = {
	id: string
	value: string
}[]

export interface YCBM_Intents_Create {
	subdomain: YCBM_Subdomain,
	selections: {
		form: YCBM_IntentInfo_Selections_Form
	}
}

export interface YCBM_Intents_SelectSlot {
	startsAt: Temporal.Instant_EpochMS<number>
	timeZone: Temporal.TimeZoneID
}

export interface YCBM_Intents_SelectTeamMember {
	teamMemberId: "AUTO"
}

export interface YCBM_Intents_Confirm {}

interface YCBM_IntentInfo_Base {
	id: YCBM_IntentSecret
	createdAt: Temporal.Instant_EpochMS<number>
	intentStatus: unknown
	bookingId: unknown
	selections: {
		appointmentTypeIds: unknown
		duration: unknown
		form: YCBM_IntentInfo_Selections_Form
		location: unknown
		startsAt: Temporal.Instant_EpochMS<number>
		teamMemberId: unknown
		timeZone: Temporal.TimeZoneID
		units: unknown
	}
}

export interface YCBM_IntentInfo_SettingUp extends YCBM_IntentInfo_Base {
	intentStatus: 'SETTING_UP'
	bookingId: null
}

export interface YCBM_IntentInfo_Confirmed extends YCBM_IntentInfo_Base {
	intentStatus: 'CONFIRMED'
	bookingId: string
}

export type YCBM_IntentInfo = YCBM_IntentInfo_SettingUp | YCBM_IntentInfo_Confirmed

export interface YCBM_IntentAvailabilityKeyResponse {
	key: YCBM_AvailabilityKey
}

export interface YCBM_AvailabilitiesResponse {
	slots: {
		freeUnits: number
		startsAt: Temporal.Instant_EpochMS<string>
	}[]
}

interface YCBM_Answer {
	code: string
	string: string
}

export interface YCBM_BookingInfo {
	accountID: YCBM_AccountID
	cancelled: boolean
	profileId: YCBM_ProfileID
	startsAtUTC: Temporal.Instant_ISO
	endsAtUTC: Temporal.Instant_ISO
	timeZone: Temporal.TimeZoneID
	durationMinutes: number
	ref: YCBM_RefRaw
	intentId: YCBM_IntentSecret
	answers: YCBM_Answer[]
	noShow: boolean
	noShowAt?: Temporal.Instant_ISO
	notes?: string
}

type YCBM_BookingsResponse = YCBM_BookingInfo[]

export function YCBM_formatRef(ref: YCBM_RefRaw): YCBM_RefFormatted {
	if (ref.length != 12) {
		throw new Error()
	}
	return `${ref.substring(0, 4)}-${ref.substring(4, 8)}-${ref.substring(8, 12)}` as YCBM_RefFormatted
}

export class YCBMRemote {
	constructor(private uri: URIObject) {}

	get api() { return this.uri.api }
	get accountID() { return this.uri.username as YCBM_AccountID | undefined }
}

export async function YCBM_Profiles_list(ycbm: YCBMRemote) {
	const profiles = new Array<YCBM_ProfileInfo>()
	const data = await ycbm.api.get(`v1/profiles?ownerId=${ycbm.accountID}`).json() as YCBM_ProfilesResponse
	profiles.push(...data)
	return profiles
}

export async function YCBM_Intents_create(ycbm: YCBMRemote, params: YCBM_Intents_Create) {
	return await ycbm.api.post(`v1/intents`, {json: params}).json() as YCBM_IntentInfo_SettingUp
}

export async function YCBM_Intents_getAvailabilityKey(ycbm: YCBMRemote, intentSecret: YCBM_IntentSecret) {
	return await ycbm.api.get(`v1/intents/${intentSecret}/availabilitykey`).json() as YCBM_IntentAvailabilityKeyResponse
}

export async function YCBM_Intents_getAvailability(ycbm: YCBMRemote, availabilityKey: YCBM_AvailabilityKey) {
	return await ycbm.api.get(`v1/availabilities/${availabilityKey}`).json() as YCBM_AvailabilitiesResponse
}

// NOTE: selectSlot and selectTeamMember look like they could be merged.
// In fact - they can't, because of YCBM's bug ("working-as-intented").

export async function YCBM_Intents_selectSlot(ycbm: YCBMRemote, intentSecret: YCBM_IntentSecret, params: YCBM_Intents_SelectSlot) {
	return await ycbm.api.patch(`v1/intents/${intentSecret}/selections`, {json: params}).json() as YCBM_IntentInfo_SettingUp
}

export async function YCBM_Intents_selectTeamMember(ycbm: YCBMRemote, intentSecret: YCBM_IntentSecret, params: YCBM_Intents_SelectTeamMember) {
	return await ycbm.api.patch(`v1/intents/${intentSecret}/selections`, {json: params}).json() as YCBM_IntentInfo_SettingUp
}

export async function YCBM_Intents_confirm(ycbm: YCBMRemote, intentSecret: YCBM_IntentSecret, params: YCBM_Intents_Confirm = {}) {
	return await ycbm.api.patch(`v1/intents/${intentSecret}/confirm`, {json: params}).json() as YCBM_IntentInfo_Confirmed
}

export async function YCBM_Intents_cancel(ycbm: YCBMRemote, intentSecret: YCBM_IntentSecret) {
	await ycbm.api.patch(`v1/intents/${intentSecret}/booking/cancel`, {json: {}})
}

export async function YCBM_Bookings_list(ycbm: YCBMRemote, {search}: {search?: string} = {}) {
	const bookings = new Array<YCBM_BookingInfo>()
	let resp: KyResponse | null = await ycbm.api.get(`v1/${ycbm.accountID}/bookings`, {
		searchParams: {
			...(search !== undefined ? {search} : {}),
			from: '2000-01-01T00:00:00Z',
			direction: 'forwards',
			fields: '*,answers.code,answers.string',
		},
	})
	let data = await resp.json() as YCBM_BookingsResponse
	while (resp && data.length) {
		bookings.push(...data)
		console.debug(bookings.length)
		const links = parseLinkHeaders(resp.headers.get('link') ?? '')
		const next = links.get('next')
		if (next) {
			resp = await ycbm.api.get(next, {prefixUrl: ''})
			data = await resp.json() as YCBM_BookingsResponse
		} else {
			resp = null
			data = []
		}
	}
	return bookings
}

function parseLinkHeaders(linkHeaders: string) {
	return new Map(linkHeaders.split(',').map(linkHeader => {
		const segments = linkHeader.split(';').map(seg => seg.trim())
		const rawUri = segments.shift()!
		if (rawUri[0] !== '<' || rawUri[rawUri.length - 1] !== '>') {
			throw new Error('Invalid link header')
		}
		const uri = decodeURIComponent(rawUri.substring(1, rawUri.length - 1))
		const params = new Map(segments.map(p => {
			const eqPos = indexOf(p, '=')
			const key = p.substring(0, eqPos ?? undefined).trim()
			const rawValue = eqPos ? p.substring(eqPos + 1).trim() : ''
			const value = rawValue[0] === '"' ? rawValue.substring(1, rawValue.length - 1) : rawValue
			return [
				key,
				value,
			]
		}))
		return [
			params.get('rel') ?? '',
			uri,
		]
	}))
}

function indexOf(haystack: string, s: string) {
	const pos = haystack.indexOf(s)
	if (pos === -1) return null
	return pos
}

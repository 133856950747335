import { HTTPError, ky, KyHooks } from "@imago/api-client"

export interface GoogleWorkspaceRemote {
	getAuth(): Promise<Google_Auth | null | undefined>
}

export interface Google_Auth {
	accessToken?: string
	quotaProjectId?: string
}

export function encodePathParameter(value: string) {
	return encodeURIComponent(value).replaceAll('.', '%2E')
}

function JSON_blobify(data: unknown) {
	return new Blob([JSON.stringify(data)], {type: 'application/json; charset=UTF-8'})
}

function makeMultipartRelated(parts: Blob[]) {
	const boundary = crypto.randomUUID()

	const type = `multipart/related; boundary=${boundary}`
	const body = []
	for (const part of parts) {
		body.push(`--${boundary}\r\ncontent-type: ${part.type}\r\n\r\n`)
		body.push(part)
		body.push('\r\n')
	}
	body.push(`--${boundary}--`)
	return new Blob(body, {type})
}

export type GAPI_SkipProps = 'media' | 'requestBody' | 'auth' | '$.xgafv' | 'access_token' | 'alt' | 'callback' | 'key' | 'oauth_token' | 'prettyPrint' | 'uploadType' | 'upload_protocol'

export class GoogleAPI {
	constructor(private urlPrefix: string, private uploadUrlPrefix?: string, private defaultOptions: Record<string, string | number | boolean | undefined> = {}) {	}

	async call<T>(
		remote: GoogleWorkspaceRemote,
		method: string,
		path: string,
		options: Record<string, (string | number | boolean)[] | string | number | boolean | undefined>,
		data?: {} | {media?: Blob} | undefined,
	): Promise<T>;
	async call(
		remote: GoogleWorkspaceRemote,
		method: string,
		path: string,
		options: Record<string, (string | number | boolean)[] | string | number | boolean | undefined>,
		data: {} | {media?: Blob} | undefined,
		alt: 'media',
	): Promise<Blob>;
	async call(
		remote: GoogleWorkspaceRemote,
		method: string,
		path: string,
		options: Record<string, (string | number | boolean)[] | string | number | boolean | undefined>,
		data?: {media?: Blob},
		alt?: 'media',
	) {
		const hooks: KyHooks = {
			beforeRequest: [
				async request => {
					const auth = await remote.getAuth()
					if (auth?.accessToken) {
						request.headers.set('authorization', `Bearer ${auth.accessToken}`)
					}
					if (auth?.quotaProjectId) {
						request.headers.set('X-Goog-User-Project', auth.quotaProjectId)
					}
				}
			]
		}
		const _api = ky.extend({
			timeout: false,
			prefixUrl: this.urlPrefix,
			hooks,
		})
		const _upload_api = this.uploadUrlPrefix ? ky.extend({
			timeout: false,
			prefixUrl: this.uploadUrlPrefix,
			hooks,
		}) : undefined

		options = {...this.defaultOptions, ...options}

		let result
		if (data && data.media) {
			if (!_upload_api) {
				throw new Error('API does not support file upload.')
			}
			const metadata = {...data}
			delete metadata.media
			result = _upload_api(path, {
				method,
				searchParams: {
					...options,
					uploadType: 'multipart',
				},
				body: makeMultipartRelated([JSON_blobify(metadata), data.media]),
			})
		} else {
			result = _api(path, {
				method,
				searchParams: Object.entries(options).flatMap(([k, v]) => v === undefined ? [] : v instanceof Array ? v.map(vv => [k, vv]) : [[k, v]]),
				json: data,
			})
		}
		try {
			return alt === 'media' ? await result.blob() : await result.json()
		} catch (e) {
			if (e instanceof HTTPError) {
				e.message += ` (${await e.response.text()})`
			}
			throw e
		}
	}
}

import { useSubscribe, useSubscribed } from "../../PaymentDialog.tsx"
export { useSubscribed }

function _Subscribe() {
	const subscribe = useSubscribe()!

	return <form onSubmit={async ev => {
		ev.stopPropagation()
		ev.preventDefault()
		subscribe.mutate()
	}}>
		<p>The Imago subscription covers prescription renewals and adjustments, supervision and guidance over your process, and support by our team.</p>
		<p>It’s €20/month.</p>
		{/* counted from the moment you receive the prescription */}
		{subscribe.trialAvailable ? <p><strong>The first month is on us.</strong></p> : <></>}
		<div className="row">
			<button type="submit" disabled={subscribe.isPending}>Set up the Imago subscription</button>
		</div>
	</form>
}

export function Subscribe() {
	const done = useSubscribed()
	return <section data-done={done ? true : undefined}>
		<h2>Set up the Imago subscription</h2>
		{!done ? <_Subscribe/> : <></>}
	</section>
}

import { useContext, useEffect } from "react"
import { PageCloseContext } from "../App.tsx"
import { TakeBloodTests, useBloodTestsTaken } from "./tasks/TakeBloodTests.tsx"
import { ReviewDraft, useDraftReviewed } from "./tasks/ReviewDraft.tsx"

export function RenewPrescription() {
	const close = useContext(PageCloseContext)

	const bloodTestsDone = useBloodTestsTaken()
	const prescriptionRequestDone = useDraftReviewed()

	const done = bloodTestsDone && prescriptionRequestDone
	useEffect(() => done ? close() : undefined)

	return <main>
		<div>
			<hgroup>
				<button onClick={ev => {
					ev.stopPropagation()
					ev.preventDefault()
					close()
				}}>
					<span className="material-symbols-outlined">arrow_back</span>
				</button>
				<h1>Renew a prescription</h1>
			</hgroup>
			<hr data-total/>
			<TakeBloodTests/>
			<hr data-total/>
			<ReviewDraft/>
			<hr data-total/>
			<section>
				<h2>Next steps</h2>
				<p>Our physician will check your lab results and your comments, and issue a new prescription.</p>
			</section>
		</div>
	</main>
}
